import libtess from 'libtess';
import {getEmptyAaabb, ModelVisualizer, Vector2, Webcad} from 'webcad';
import {
  Node,
  Mesh,
  Effect,
  ShaderMaterial,
  VertexBuffer,
  Buffer,
  BoundingInfo,
  SubMesh,
  AbstractMesh,
  Engine,
  StencilState,
  VertexData,
  Vector3 as B_Vector3,
  Nullable,
  Scene
} from 'webcad/babylonjs/core';
import {ShapeWithHoles} from '../model/shape-with-holes';
import {splitToRegions, Tesselator} from '../utils/bending';

export interface ExpandedMetalVisualizerModel {
  gltfId: string;
  shape: ShapeWithHoles;
  lwd: number;
  swd: number;
  offsetX: number;
  offsetY: number;
  feedrate: number;
}
export class ExpandedMetalVisualizer implements ModelVisualizer<ExpandedMetalVisualizerModel> {
  private model: ExpandedMetalVisualizerModel;
  private material: ShaderMaterial;
  private mesh: ExpandedMetal = null;

  constructor() {}
  protected parentNode: Node;

  init(rootNode: Node, model: ExpandedMetalVisualizerModel, webcad: Webcad): Promise<void> {
    this.model = model;
    this.parentNode = rootNode;
    createShaders();
    const scene = this.parentNode.getScene();
    this.material = new ShaderMaterial(
      'expmetal',
      scene,
      {
        vertex: 'expmetal',
        fragment: 'expmetal',
      },
      {
        attributes: ['position', 'instancePos', 'normal'],
        uniforms: ['worldViewProjection', 'worldView'],
      }
    );
    this.material.backFaceCulling = true;
    this.material.cullBackFaces = true;
    return Promise.resolve();
  }

  private expMesh(gltfId: string, shape: ShapeWithHoles, lwd: number, swd: number, offsetX: number, offsetY: number, feedrate: number): ExpandedMetal {
    const scene = this.parentNode.getScene();
    const mesh = (scene as any).gltfs[gltfId];

    const aabb = shape.aabb;
    lwd /= 1000;
    const hlwd = lwd / 2.0;
    swd /= 1000;
    offsetX /= 1000;
    offsetY /= 1000;
    feedrate /= 1000;

    swd = Math.sqrt( swd * swd + feedrate * feedrate*4);

    offsetX = offsetX - Math.ceil(((offsetX - swd / 2.0) - aabb.min.x ) / swd) * swd;
    offsetY = offsetY - Math.ceil(((offsetY - lwd / 2.0) - aabb.min.y ) / lwd) * lwd;
    const sx = Math.ceil((aabb.max.x - aabb.min.x)  / swd) + 1;
    const sy = Math.ceil((aabb.max.y - aabb.min.y)  / hlwd) + 1;


    //const sx = 140;
    //const sy = 100;
    // const sx = 400;
    // const sy = 400;
    const instancedMesh = new ExpandedMetal(
      'exp-met',
      sx * sy,
      scene
    );
    // const instancedMesh = new InstancedMesh(
    //   'exp-met',
    //   sx * sy,
    //   scene
    // );
    instancedMesh.setVerticesData(
      VertexBuffer.PositionKind,
      mesh.getVerticesData(VertexBuffer.PositionKind, false),
      false
    );
    instancedMesh.setVerticesData(
      VertexBuffer.NormalKind,
      mesh.getVerticesData(VertexBuffer.NormalKind, false),
      false
    );
    instancedMesh.setIndices(mesh.getIndices());
    const positions: Float32Array = new Float32Array(400 * 400 * 2);

    for (let y = 0; y < sy; y++) {
      const offset = y % 2 ? 0 : (swd/2);
      for (let x = 0; x < sx; x++) {
        positions[(y * sx + x) * 2] = x * swd + offset + offsetX;
        positions[(y * sx + x) * 2 + 1] = y * hlwd + offsetY;
      }
    }

    const instancesBuffer = new Buffer(
      scene.getEngine(),
      positions,
      false,
      2,
      false,
      true
    );
    (instancedMesh as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      'instancePos',
      0,
      2
    );
    instancedMesh.setVerticesBuffer(positionsVertexBuffer);
    instancedMesh.material = this.material;
    instancedMesh.setBoundingInfo( new BoundingInfo(
      new B_Vector3(-100, -100, -100),
      new B_Vector3(100, 100, 100)
    ));
    // scene.addMesh(instancedMesh);
    // scene.addMesh(mesh);
    // instancedMesh.renderingGroupId = 7;
    return instancedMesh;
  }

  volumeMesh(shapeWithHoles: ShapeWithHoles, depth: number): Mesh {
    const cutOutTess = new Tesselator(
      libtess.windingRule.GLU_TESS_WINDING_POSITIVE
    );
    const shapeContours: number[][] = [];
    cutOutTess.addPolyline(shapeWithHoles.conture, shapeContours);
    if (shapeWithHoles.holes) {
      cutOutTess.addPolylines(shapeWithHoles.holes, shapeContours);
    }
    const tris = cutOutTess.triangulate();
    const regions = splitToRegions(tris);
    const region = regions[0];
    if ( region ) {
      const positions: number[] = [];
      const indices: number[] = [];

      for (let i = 0; i < region.vertices.length; i++) {
        const vertex = region.vertices[i];
        positions.push(vertex.x, vertex.y, -depth/2);
      }
      for (let i = 0; i < region.vertices.length; i++) {
        const vertex = region.vertices[i];
        positions.push(vertex.x, vertex.y, depth/2);
      }

      const vo1 = 0;
      for (let i = 0; i < region.triangles.length; i += 3) {
        indices.push(vo1 + region.triangles[i]);
        indices.push(vo1 + region.triangles[i + 1]);
        indices.push(vo1 + region.triangles[i + 2]);
      }
      const vo2 = vo1 + region.vertices.length;
      for (let i = 0; i < region.triangles.length; i += 3) {
        indices.push(vo2 + region.triangles[i]);
        indices.push(vo2 + region.triangles[i + 2]);
        indices.push(vo2 + region.triangles[i + 1]);
      }

      let i1,
        i2,
        i3,
        i4;

      for (let i = 0; i < region.edges.length; i += 2) {
        i1 = vo1 + region.edges[i + 1];
        i2 = vo1 + region.edges[i];
        i3 = vo2 + region.edges[i + 1];
        i4 = vo2 + region.edges[i];
        //indices.push(1x, v1y, v1z, v2x, v2y, v2z, v3x, v3y, v3z, v4x, v4y, v4z, v3x, v3y, v3z, v2x, v2y, v2z);
        indices.push(i1, i2, i3, i4, i3, i2);
      }



      const mesh = new Mesh('expmet_volume');
      const vertexData = new VertexData();
      vertexData.positions = positions;
      vertexData.indices = indices;
      vertexData.applyToMesh(mesh);
      return mesh;

    }

  }
  updateVisualization(newModel: ExpandedMetalVisualizerModel): void {
    const scene = this.parentNode.getScene();
    if (this.model !== newModel) {
      this.dispose();
      if (!!newModel  && !!newModel.gltfId && newModel.shape.conture.length > 0) {
        this.mesh = this.expMesh(newModel.gltfId, newModel.shape, newModel.lwd, newModel.swd, newModel.offsetX, newModel.offsetX, newModel.feedrate);
        const aabb = (this.parentNode.getScene() as any).gltfs[newModel.gltfId].getBoundingInfo();
        this.mesh.volumeMesh = this.volumeMesh(newModel.shape, aabb.boundingBox.maximum.z - aabb.boundingBox.minimum.z);
        this.mesh.volumeMesh.material = new ShaderMaterial(
          'expmetal',
          scene,
          {
            vertex: 'expmetal',
            fragment: 'expmetal',
          },
          {
            attributes: ['position', 'instancePos', 'normal'],
            uniforms: ['worldViewProjection', 'worldView'],
          }
        );
      }
    }
    this.model = newModel;
  }

  dispose(): void {
    if (this.mesh) {
      this.mesh.volumeMesh.dispose();
      this.mesh.dispose();
    }
  }

}

class ExpandedMetal extends Mesh {
  public count = 0;
  public volumeMesh: Mesh;
  constructor(name: string, count: number, scene?: Nullable<Scene>, parent?: Nullable<Node>, source?: Nullable<Mesh>, doNotCloneChildren?: boolean, clonePhysicsImpostor?: boolean) {
    super(name, scene, parent, source, doNotCloneChildren, clonePhysicsImpostor);
    this.count = count;
  }
  _checkOcclusionQuery() {
    this.isOccluded = false;
    return this.isOccluded;
  }

  renderMesh(mesh: Mesh, colorWrite: boolean , ccw: boolean, stencilState: StencilState,  instancesCount?: number) {
    const subMesh = mesh.subMeshes[0];
    const effect = mesh.material._getDrawWrapper().effect;
    const engine = mesh._scene.getEngine();
    const material = mesh.material;
    let fillMode = material.fillMode;

    mesh._internalAbstractMeshDataInfo._isActive = false;
    if (!mesh._geometry || !mesh._geometry.getVertexBuffers() || (!mesh._unIndexed && !mesh._geometry.getIndexBuffer())) {
      return mesh;
    }

    // init material  (effect)
    if ( !mesh.material.isReady(mesh, false) ) {
      return mesh;
    }

    // bind
    engine.enableEffect(effect);
    engine.setState(true, 0, false, ccw, false, stencilState, 0);
    engine.setColorWrite(colorWrite);

    mesh._bind(subMesh, effect, fillMode, false);
    const world = mesh.getWorldMatrix();
    (material as ShaderMaterial).bind(world, mesh, effect);

    // draw
    fillMode = mesh._getRenderingFillMode(fillMode);
    mesh._draw(subMesh, fillMode, instancesCount);

    // unbid
    engine.unbindInstanceAttributes();
    material.unbind();

  }

  render(subMesh: SubMesh, enableAlphaMode: boolean, effectiveMeshReplacement?: AbstractMesh): Mesh {
    // return super.render(subMesh, enableAlphaMode, effectiveMeshReplacement);

    const engine = this._scene.getEngine();
    // const d = engine.getDepthFunction();

    const ss = new StencilState();
    /*
    ss.enabled = true;
    ss.opStencilDepthPass = Engine.REPLACE;
    ss.mask = 0xFF;
    ss.func = Engine.ALWAYS;
    ss.funcRef = 1;
    this.renderMesh(this.volumeMesh, false, false, ss, undefined);

    ss.func = Engine.EQUAL;
    ss.opStencilDepthPass = Engine.INCR;
    ss.funcRef = 1;
    engine.setDepthFunctionToGreater();
    this.renderMesh(this, false, true, ss, this.count);

    ss.enabled = false;
    engine.setDepthFunctionToLessOrEqual();
    this.renderMesh(this.volumeMesh, false, true, ss, undefined);

    ss.enabled = true;
    ss.func = Engine.NOTEQUAL;
    ss.funcRef = 2;
    engine.setDepthFunctionToGreater();
    this.renderMesh(this, true, true, ss, this.count);
    */
    this.renderMesh(this.volumeMesh, false, true, ss, undefined);

    ss.enabled = true;
    ss.opStencilDepthPass = Engine.REPLACE;
    ss.mask = 0xFF;
    ss.func = Engine.ALWAYS;
    ss.funcRef = 1;
    this.renderMesh(this, false, true, ss, this.count);

    engine.clear(null, false, true, false);

    ss.enabled = false;
   this.renderMesh(this.volumeMesh, false, false, ss, undefined);

    ss.enabled = true;
    ss.mask = 0x00;
    ss.func = Engine.NOTEQUAL;
    ss.funcRef = 1;
    this.renderMesh(this, true, false, ss, this.count);

    engine.clear(null, false, true, false);

    // engine.setDepthFunction(d);
    return this;

  }
}

function createShaders() {
  Effect.ShadersStore['expmetalVertexShader'] =
    `
    precision highp float;
    attribute vec3 position;
    attribute vec3 normal;
    attribute vec2 instancePos;
    uniform mat4 worldViewProjection;
    uniform mat4 worldView;
    varying vec4 vColor;
    void main(void) {
      vec3 n = vec3(worldView * vec4(normal, 0.0));
      float diffuse = max(dot(n, vec3(0.0 ,0.0 , -1.0)), 0.3);
      vColor = vec4( vec3(0.7, 0.7, 0.7) * diffuse, 1.0);
      // vColor = vec4( n, 1.0);
      vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);
      gl_Position = worldViewProjection * vec4(pos, 1.0);
    }
  `;
  Effect.ShadersStore['expmetalFragmentShader'] =
    `
    precision highp float;
    varying vec4 vColor;
    void main(void) {
      gl_FragColor = vColor;
    }
  `;
}
