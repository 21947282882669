import { ElementRef } from "@angular/core";
import { RotateCamera, View3D } from "webcad";
import { Vector3 } from "webcad/math";
import { Aabb2 } from "webcad/models/aabb.model";
import { MevacoPage, initialMevacoPage } from "../model/mevaco-page.model";
import { PlateMeshProvider } from "../providers/plate-mesh.provider";
import { View3dProvider } from "../providers/view3d.provider";
import { MevacoPageVisualizer } from "./mevaco-page.visualizer";
import {RenderingOrder} from "./rendering-order";
import {Camera, Color3, Color4, CubicEase, EasingFunction, RenderingManager, Tools, Animation, Vector3 as B_Vector3} from "webcad/babylonjs/core";
export class MevacoView3d extends View3D<MevacoPage> {
  private mevacoCamera: RotateCamera;
  private backgroundColor = new Color3(1, 1, 1);
  private renderingOrder: RenderingOrder;

  constructor(
    private renderTarget: ElementRef,
    private plateMeshProvider: PlateMeshProvider,
    private view3dProvider: View3dProvider
  ) {
    super(
      renderTarget.nativeElement,
      "/assets/",
      "arrows.babylon",
      "/assets/floor-textures/"
    );
  }

  init(): void {
    this.mevacoCamera = new RotateCamera(
      "MainCamera",
      0,
      0,
      0,
      B_Vector3.Zero(),
      this.scene
    );
    this.mevacoCamera.minZ = 0.01;
    this.mevacoCamera.maxZ = 20;
    this.mevacoCamera.lowerRadiusLimit = 0.1;
    this.mevacoCamera.upperRadiusLimit = 13;
    this.mevacoCamera.setPosition(new B_Vector3(0, 0, -10));
    this.mevacoCamera.inertia = 0;
    this.mevacoCamera.panningInertia = 0;
    this.mevacoCamera.panningSensibility = 140;
    // const mevacoCamera = this.mevacoCamera as unknown;
    this.camera = this.mevacoCamera;
    this.scene.activeCamera = this.camera;
    this.scene.clearColor = new Color4(
      this.backgroundColor.r,
      this.backgroundColor.g,
      this.backgroundColor.b,
      1
    );
    this.scene.ambientColor = new Color3(1, 0, 0);
    this.scene.blockMaterialDirtyMechanism = true;
    RenderingManager.MAX_RENDERINGGROUPS = 7;

    this.scene.setRenderingOrder(2);
    this.renderingOrder = new RenderingOrder();
    this.renderingOrder.init(this.scene);

    super.init(
      initialMevacoPage,
      new MevacoPageVisualizer(this.plateMeshProvider)
    );
  }

  createScreenShotUsingRenderTarget(model: MevacoPage): Promise<string> {
    const oldModel: MevacoPage = this.cloneObject<MevacoPage>(this.model);
    this.updateModel(model);
    this.render();

    const renderingOrder = this.renderingOrder;
    class RenderingManagerOrder extends RenderingManager {
      constructor(scene) {
        super(scene);
        renderingOrder.initRenderManager(this);
      }
    }
    /*
    const old = BABYLON.RenderingManager as any;
    // @ts-ignore
    BABYLON.RenderingManager = RenderingManager;
    */

    const result = new Promise<string>((resolve) => {
      const mevacoCamera = this.mevacoCamera as unknown;
      const createScreenshotUsingRenderTarget = Tools.CreateScreenshotUsingRenderTarget as any;
      createScreenshotUsingRenderTarget(
        this.engine,
        mevacoCamera as Camera,
        {
          width: 250 * 4,
          height: 150 * 4,
        },
        async (data) => {
          this.updateModel(oldModel);
          this.render();
          resolve(await resizeDataURL(data, 250, 150));
        },
        "image/png",
        null,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (renderTargetTexture: any) => {
          renderTargetTexture._renderingManager = new RenderingManagerOrder(this.scene);
        }

      );
    });

    // @ts-ignore
    // BABYLON.RenderingManager = old;
    return result;
  }

  zoomToFitAaBb(cameraToSet: RotateCamera, aabb: Aabb2) {
    const currentRadius = cameraToSet.radius;
    let wantedRadius =
      Math.max(aabb.max.y - aabb.min.y, aabb.max.x - aabb.min.x) * 1.5;
    if (wantedRadius === 0) {
      wantedRadius = 5;
    }
    const ghostCamera = new RotateCamera(
      "ghostCamera",
      cameraToSet.alpha,
      cameraToSet.beta,
      cameraToSet.radius,
      cameraToSet.target,
      this.scene
    );
    // if (aabb.max.y > 100) {
    //   denominator *= 1000;
    // }
    ghostCamera.setPosition(
      new B_Vector3(
        (aabb.max.x + aabb.min.x) * 0.5,
        (aabb.max.y + aabb.min.y) * 0.5,
        cameraToSet.position.z
      )
    );
    ghostCamera.setTarget(
      new B_Vector3(
        (aabb.max.x + aabb.min.x) * 0.5,
        (aabb.max.y + aabb.min.y) * 0.5,
        0
      )
    );
    const easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    const animation = new Animation(
      "cameraRotateTo",
      "radius",
      60,
      Animation.ANIMATIONTYPE_FLOAT
    );
    const keys = [
      {
        frame: 0,
        value: currentRadius,
      },
      {
        frame: 60,
        value: wantedRadius,
      },
    ];
    const animationAlpha = new Animation(
      "cameraMoveTo",
      "alpha",
      60,
      Animation.ANIMATIONTYPE_FLOAT
    );
    const keysAlpha = [
      {
        frame: 0,
        value: cameraToSet.alpha,
      },
      {
        frame: 60,
        value: ghostCamera.alpha,
      },
    ];
    const animationBeta = new Animation(
      "cameraMoveTo",
      "beta",
      60,
      Animation.ANIMATIONTYPE_FLOAT
    );
    const keysBeta = [
      {
        frame: 0,
        value: cameraToSet.beta,
      },
      {
        frame: 60,
        value: ghostCamera.beta,
      },
    ];
    const animationPos = new Animation(
      "cameraMoveTo",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3
    );
    const keysPos = [
      {
        frame: 0,
        value: cameraToSet.position,
      },
      {
        frame: 60,
        value: ghostCamera.position,
      },
    ];
    animation.setKeys(keys);
    animation.setEasingFunction(easingFunction);
    animationAlpha.setKeys(keysAlpha);
    animationAlpha.setEasingFunction(easingFunction);
    animationBeta.setKeys(keysBeta);
    animationBeta.setEasingFunction(easingFunction);
    animationPos.setKeys(keysPos);
    animationPos.setEasingFunction(easingFunction);
    cameraToSet.animations.push(animationAlpha);
    cameraToSet.animations.push(animationBeta);
    cameraToSet.animations.push(animationPos);
    cameraToSet.animations.push(animation);
    cameraToSet.setTarget(ghostCamera.target);
    this.scene.beginAnimation(cameraToSet, 0, 100);
    cameraToSet.animations = [];
    ghostCamera.dispose();
  }

  setCameraToFitAaBb(cameraToSet: RotateCamera, aabb: Aabb2): void {
    let wantedRadius =
      Math.max(aabb.max.y - aabb.min.y, aabb.max.x - aabb.min.x) * 1.5;
    if (wantedRadius === 0) {
      wantedRadius = 10;
    }
    const ghostCamera = new RotateCamera(
      "ghostCamera",
      cameraToSet.alpha,
      cameraToSet.beta,
      wantedRadius,
      cameraToSet.target,
      this.scene
    );
    ghostCamera.setPosition(
      new B_Vector3(
        (aabb.max.x + aabb.min.x) * 0.5,
        (aabb.max.y + aabb.min.y) * 0.5,
        -wantedRadius
      )
    );
    ghostCamera.setTarget(
      new B_Vector3(
        (aabb.max.x + aabb.min.x) * 0.5,
        (aabb.max.y + aabb.min.y) * 0.5,
        0
      )
    );
    cameraToSet.radius = wantedRadius;
    cameraToSet.alpha = ghostCamera.alpha;
    cameraToSet.beta = ghostCamera.beta;
    cameraToSet.setTarget(ghostCamera.target.clone());
    cameraToSet.setPosition(ghostCamera.position.clone());
    ghostCamera.dispose();
  }

  updateBackgroundColor(newColor: Vector3) {
    this.scene.clearColor = new Color4(
      newColor.x,
      newColor.y,
      newColor.z,
      1
    );
    this.backgroundColor = new Color3(
      newColor.x,
      newColor.y,
      newColor.z
    );
  }
}

function resizeDataURL(dataUrl, wantedWidth, wantedHeight): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    // We create an image to receive the Data URI
    const img = document.createElement("img");

    // When the event "onload" is triggered we can resize the image.
    img.onload = function (): void {
      // We create a canvas and get its context.
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

      resolve(canvas.toDataURL());
    };

    // We put the Data URI in the image's src attribute
    img.src = dataUrl;
  });
}
