import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, filter, from, Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {currentGltfFileName, getGltfs, getProductType, MevacoState} from '../reducers';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {GltfLoaded, LOAD_GLTF, LoadGltf} from '../actions';
import {View3dProvider} from '../../providers/view3d.provider';
import {Injectable} from '@angular/core';
import { Mesh, Vector3, Matrix, StandardMaterial, Color3, DirectionalLight, Geometry, Node, QuadraticErrorSimplification } from 'webcad/babylonjs/core';
import {environment} from "../../../environments/environment";

@Injectable()
export class ExpandedMetalMeshesEffects {
  constructor(
    private actions: Actions,
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider
  ) {}

  initLoad = createEffect(() =>
    combineLatest([
      this.store.select(currentGltfFileName),
      this.view3dProvider.engineReady// we need to just wait for it
    ])
    .pipe(
      withLatestFrom(this.store),
      filter(([[gltfFileName, _], mevacoState]) => gltfFileName && mevacoState.model.gltfs[gltfFileName] === undefined && !!this.view3dProvider.mevacoView3D),
      map(([[gltfFileName, _], mevacoState]) => {
        const stremoe = mevacoState.model.productConfiguration.configuration?.stremoe;
        const feedrate = +(mevacoState.model.productConfiguration.extendedMetal.feedrate);
        return new LoadGltf(gltfFileName, stremoe.sizeS, feedrate );
      })
    )
  );

  loadGltf = createEffect(() => {
    return this.actions.pipe(
      ofType(LOAD_GLTF),
      switchMap((action: LoadGltf) => {
        const p = this.view3dProvider.mevacoView3D.load(`${ environment.api_url}/api/.glb?name=`, action.payload + '.glb');
        //const p = this.view3dProvider.mevacoView3D.load('/assets/gltf/expmet/', 'pattern22_2.glb');
        const o = from(p);
        const a = o.pipe(
          switchMap(scene => {
            return from(new Promise<GltfLoaded>(reslove => {
              const root = scene.rootNodes.find(n => n.id === '__root__') as Mesh;

              const meshes: Mesh[] = [];
              getChildrenMeshes(root, meshes);
              scene.removeMesh(root);

              const mesh = Mesh.MergeMeshes(meshes);
              // const mesh = meshes[0];
              // mesh.refreshBoundingInfo();
              const bi = mesh.getBoundingInfo();

              let m = Matrix.Identity();
              m = m.multiply(Matrix.Translation(-bi.boundingBox.center.x, -bi.boundingBox.center.y, -bi.boundingBox.center.z));
              m = m.multiply(Matrix.Scaling(0.001, 0.001, 0.001));
              m = m.multiply(Matrix.RotationX(Math.PI / 2));
              m = m.multiply(Matrix.RotationZ(Math.PI / 2));

              const x = bi.boundingBox.maximum.x - bi.boundingBox.minimum.x;
              const y = bi.boundingBox.maximum.y - bi.boundingBox.minimum.y;
              const z = bi.boundingBox.maximum.z - bi.boundingBox.minimum.z;

              const alpha =  Math.atan2(action.feedrate * 2, action.swd);
              m = m.multiply(Matrix.RotationY(alpha));
              mesh.bakeTransformIntoVertices(m);
              scene.removeMesh(mesh);


              (scene as any).gltfs = (scene as any).gltfs || {};
              (scene as any).gltfs[action.payload] = mesh;
              reslove(new GltfLoaded(action.payload));

              /*
              const simplificator = new QuadraticErrorSimplification(mesh);
              simplificator.simplify({ quality: 0.5, distance: 0 }, function(simplified) {
                (scene as any).gltfs = (scene as any).gltfs || {};
                (scene as any).gltfs[action.payload] = simplified;
                reslove(new GltfLoaded({nodeName: 'pattern22', gltfFileName: action.payload}));
              });
               */
            }));
          })
        );
        return a;
      })
    );
  });


}


function getChildrenMeshes(root: Node, meshes: Mesh[]) {
  if (!!(root as Mesh).geometry) {
    meshes.push(root as Mesh);
  }
  root.getChildren().forEach( child => getChildrenMeshes(child, meshes) );
}
