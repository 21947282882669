<div class="configurator-accordion-wrapper">
  <h2
    class="configurator-accordion-title accordion-title--color-default"
  >
    {{ translate("Mesh", "designer") }}
  </h2>

  <dropdown
    [model]="expandedMetalTypeDropdown"
    [tooltipConfig]=" {
      fieldName: 'extendedMetal.type',
      helpText: 'Shape Tooltip'
    }"
  >
  </dropdown>

  <dropdown
    [model]="expandedMetalSizeDropdown"
    [tooltipConfig]=" {
      fieldName: 'extendedMetal.size',
      helpText: 'Expanded Metal Format Tooltip'
    }"
  >
  </dropdown>

  <div class="marginComboBox">
    <label class="labelText">
      <span class="radiodesc">{{translate('schmal')}}</span>
      <input class="radioButton" type="radio" value="schmal"
             [checked]="(stegcluster|async) === 'schmal'"
             [disabled]="!(schmalPossible|async)"
             (change)="setStegcluster('schmal')"
      >
      <span class="checkmark"></span>
    </label>
    <label class="labelText" style="margin-left: 10px">
    <span class="radiodesc">{{translate("breit")}}</span>
    <input class="radioButton" type="radio" value="breit"
      [checked]="(stegcluster|async) === 'breit'"
      [disabled]="!(breitPossible|async)"
      (change)="setStegcluster('breit')"
    >
      <span class="checkmark" ></span>
    </label>
  </div>

  <div class="feedrate">
    <div>{{minFeedrate|async}} &le; </div>
    <div class="feedrate_input">
      <debounce-input
        [inputType]="'number'"
        [value]="(feedrate|async)"
        [placeholder]="translate('Feedrate')"
        (valueChanged)="setFeedrate($event)"
      ></debounce-input>
    </div>
    <!--      (valueChanged)="setP1($event)"-->

<!--    <input class="numericInput3"-->
<!--    type="number"-->
<!--    step="0.1"-->
<!--    [placeholder]="translate('Feedrate')"-->
<!--    value={appState.extendedMetal.feedrate} -->
<!--    onChange={this.onInput3Change.bind(this)} />-->
    <div> &le; {{maxFeedrate|async}}</div>
  </div>


</div>
