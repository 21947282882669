import {ExpandedMetalVisualizerModel} from "../expanded-metal.vizualizer";
import {MevacoPage} from "../../model/mevaco-page.model";
import {
  getExpandedMetalGltfHash,
  ProductType,
  Step
} from "../../model/product-configuration/product-configuration.model";

export class ExpandedMetalViewModelProvider {
  private viewModel: ExpandedMetalVisualizerModel = {
    gltfId: null,
    shape: null,
    lwd: 100,
    swd: 100,
    offsetX: 0,
    offsetY: 0,
    feedrate: 1,
  };

  getViewModel(newModel: MevacoPage): ExpandedMetalVisualizerModel {
    const show = newModel.productConfiguration.productType === ProductType.ExtendedMetals && (newModel.productConfiguration.step === Step.pattern || newModel.productConfiguration.step === Step.design);
    if ( !show ) {
      return null;
    }

    const stremoe = newModel.productConfiguration.configuration?.stremoe;
    if (!stremoe) {
      return null;
    }

    const viewModel: ExpandedMetalVisualizerModel = show ? {
      gltfId: getExpandedMetalGltfHash(newModel.productConfiguration),
      shape: newModel.drawing?.plate?.shapeWithHoles,
      lwd: stremoe.sizeL,
      swd: stremoe.sizeS,
      feedrate: +newModel.productConfiguration.extendedMetal.feedrate,
      offsetX: 0,
      offsetY: 0
    } : null;

    //not loaded yet
    if ( !newModel.gltfs[viewModel.gltfId] ) {
      return null;
    }

    if ( this.viewModel.gltfId !== viewModel.gltfId || this.viewModel.shape !== viewModel.shape) {
      this.viewModel = viewModel;
    }
    return this.viewModel;
  }
}
